import { graphql, Link, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React, { Component, createRef } from "react"
import { FaChevronDown } from "react-icons/fa"
import Card from "../components/Card"
import FiveStats from "../components/FiveStats"
import FiveSteps from "../components/FiveSteps"
import SEO from "../components/seo"
import "./index.sass"

class IndexPage extends Component {
  constructor() {
    super()
    this.section1Ref = createRef()
  }

  scrollToSection1 = () => {
    this.section1Ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  render() {
    return (
      <>
        <SEO title="Home" />
        <StaticQuery
          query={graphql`
            query {
              coverImg: file(relativePath: { eq: "cover.jpg" }) {
                sm: childImageSharp {
                  fluid(quality: 100, maxWidth: 576) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                md: childImageSharp {
                  fluid(quality: 100, maxWidth: 768) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                lg: childImageSharp {
                  fluid(quality: 100, maxWidth: 992) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                xl: childImageSharp {
                  fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              section4Img: file(relativePath: { eq: "gradcap.jpg" }) {
                sm: childImageSharp {
                  fluid(quality: 100, maxWidth: 540) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                md: childImageSharp {
                  fluid(quality: 100, maxWidth: 360) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                lg: childImageSharp {
                  fluid(quality: 100, maxWidth: 480) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                xl: childImageSharp {
                  fluid(quality: 100, maxWidth: 570) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              cardImg: file(relativePath: { eq: "bookpreview.png" }) {
                sm: childImageSharp {
                  fluid(quality: 100, maxWidth: 540) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                md: childImageSharp {
                  fluid(quality: 100, maxWidth: 352) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                lg: childImageSharp {
                  fluid(quality: 100, maxWidth: 453) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              section6Img: file(relativePath: { eq: "campuswalking.jpg" }) {
                sm: childImageSharp {
                  fluid(quality: 100, maxWidth: 540) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                md: childImageSharp {
                  fluid(quality: 100, maxWidth: 288) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                lg: childImageSharp {
                  fluid(quality: 100, maxWidth: 384) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                xl: childImageSharp {
                  fluid(quality: 100, maxWidth: 456) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          `}
          render={data => {
            const coverImgSource = [
              data.coverImg.sm.fluid,
              {
                ...data.coverImg.md.fluid,
                media: `(min-width: 768px)`,
              },
              {
                ...data.coverImg.lg.fluid,
                media: `(min-width: 992px)`,
              },
              {
                ...data.coverImg.xl.fluid,
                media: `(min-width: 1200px)`,
              },
            ]
            const section4ImgSource = [
              data.section4Img.sm.fluid,
              {
                ...data.section4Img.md.fluid,
                media: `(min-width: 768px)`,
              },
              {
                ...data.section4Img.lg.fluid,
                media: `(min-width: 992px)`,
              },
              {
                ...data.section4Img.xl.fluid,
                media: `(min-width: 1200px)`,
              },
            ]
            const cardImgSource = [
              data.cardImg.sm.fluid,
              {
                ...data.cardImg.md.fluid,
                media: `(min-width: 768px)`,
              },
              {
                ...data.cardImg.lg.fluid,
                media: `(min-width: 992px)`,
              },
            ]
            const section6ImgSource = [
              data.section6Img.sm.fluid,
              {
                ...data.section6Img.md.fluid,
                media: `(min-width: 768px)`,
              },
              {
                ...data.section6Img.lg.fluid,
                media: `(min-width: 992px)`,
              },
              {
                ...data.section6Img.xl.fluid,
                media: `(min-width: 1200px)`,
              },
            ]
            return (
              <>
                <BackgroundImage
                  fluid={coverImgSource}
                  backgroundColor={`#9aa1a8`}
                  className="hero"
                >
                  <div className="container hero-content">
                    <h1>You were accepted to college.</h1>
                    <h2>Now what?</h2>
                  </div>
                  <div className="container scroll-btn">
                    <button onClick={this.scrollToSection1}>
                      Create your college success strategy&nbsp;
                      <FaChevronDown />
                    </button>
                  </div>
                </BackgroundImage>
                {/* ------------------------------------------------------- */}
                <div
                  className="container text-center section section-1"
                  ref={this.section1Ref}
                >
                  <p>
                    Getting accepted to college is the first step in a long
                    journey to achieving all that you want from your college
                    investment.
                  </p>
                  <h1>There is a smarter way to do college.</h1>
                  <FiveSteps />
                  <p>
                    Work with me to access to custom tools, expert advising,
                    one-on-one mentoring, coaching, and accountability. Working
                    together, we develop your personalized success strategy to
                    optimize your college experience, achieve your goals to
                    reach your fullest potential.
                  </p>
                </div>
                {/* ------------------------------------------------------- */}
                <div className="container section section-2">
                  <div className="section-2-split-layout-block">
                    <h1 className="section-2-split-layout-block-quote">
                      <span>&#8220;</span>
                      <span>No one gets</span>
                      <span>through</span>
                      <span>college or life</span>
                      <span>on their own.</span>
                      <span>Go out</span>
                      <span>and build</span>
                      <span>your base of</span>
                      <span>support.</span>
                      <span>&#8221;</span>
                    </h1>
                    <h2 className="section-2-split-layout-block-author">
                      - Michelle Obama
                    </h2>
                  </div>
                  <div className="section-2-split-layout-content">
                    <h1>Hello fellow student in the school of life,</h1>
                    <p>
                      You made the decision to go to college but sometimes you
                      wonder if you made the right choice and if your investment
                      college would be worth it.
                    </p>
                    <p>Feelings of doubt and uncertainty are normal</p>
                    <p>
                      No one can successfully get through college without the
                      support and guidance of a team of people. Working with a
                      college life coach can help ease the transition to and
                      through college.
                    </p>
                    <div className="text-center all-caps">
                      <h3>Build your success team</h3>
                      <Link to="/resource">
                        <button className="outline-btn">
                          Free guide on How to select your essential team
                          members
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* ------------------------------------------------------- */}
                <div className="container text-center section section-3">
                  <h1>The stats on college success are clear</h1>
                  <h3>What happens to the average college student?</h3>
                  <FiveStats />
                  <div className="all-caps">
                    <h3>Don't be a statistic!</h3>
                    <Link to="/service">
                      <button className="outline-btn">
                        Work with me for...
                      </button>
                    </Link>
                  </div>
                </div>
                {/* ------------------------------------------------------- */}
                <div className="container text-center section section-4">
                  <h1 className="section-4-split-layout-main">
                    <span>Right on</span>
                    <span>time</span>
                    <span>support</span>
                    <span>and expert</span>
                    <span>guidance</span>
                  </h1>
                  <BackgroundImage
                    fluid={section4ImgSource}
                    backgroundColor={`#2f2f31`}
                    className="section-4-split-layout-img"
                    style={{ backgroundPosition: "center 20%" }}
                  />
                </div>
                {/* ------------------------------------------------------- */}
                <div className="container section section-5">
                  <Card img={cardImgSource} color="#663399">
                    <div className="text-center">
                      <p>Get started with course building</p>
                      <h3>The Ultimate Course Creation Starter Kit</h3>
                      <Link to="/course">
                        <button className="outline-btn">
                          Yes, I need that!
                        </button>
                      </Link>
                    </div>
                  </Card>
                  <Card img={cardImgSource} color="#663399">
                    <div className="text-center">
                      <p>Get started with course building</p>
                      <h3>The Ultimate Course Creation Starter Kit</h3>
                      <Link to="/course">
                        <button className="outline-btn">
                          Yes, I need that!
                        </button>
                      </Link>
                    </div>
                  </Card>
                </div>
                {/* ------------------------------------------------------- */}
                <div className="container section section-6">
                  <div className="section-6-split-layout-content">
                    <h1>Facts</h1>
                    <ul>
                      <li>
                        College gives you access to a social network and
                        professional connections that lasts a lifetime.
                      </li>
                      <li>College is a significant financial investment.</li>
                      <li>
                        Getting a college degree will require dedication,
                        discipline and action to achieve success.
                      </li>
                      <li>
                        Most high paying, impactful, and fulfilling jobs will
                        require a college degree.
                      </li>
                    </ul>
                    <div className="text-center all-caps">
                      <h3>Make a wise investment.</h3>
                      <Link to="/resource">
                        <button className="outline-btn">
                          How to find the best college fit
                        </button>
                      </Link>
                    </div>
                  </div>
                  <BackgroundImage
                    fluid={section6ImgSource}
                    backgroundColor={`#a3c5d3`}
                    className="section-6-split-layout-img"
                  />
                </div>
                {/* ------------------------------------------------------- */}
                <div className="spacer-3" />
              </>
            )
          }}
        />
      </>
    )
  }
}
export default IndexPage
