import PropTypes from "prop-types"
import React from "react"

const Stat = ({ size, rad, stroke, percent, desc }) => {
  const cent = size / 2
  const cir = rad * 2 * Math.PI
  return (
    <div className="stat-item">
      <div className="stat-ring">
        <svg
          className="stat-ring-svg"
          height={`${size * 16}px`}
          width={`${size * 16}px`}
        >
          <circle
            r={`${rad * 16}px`}
            cx={`${cent * 16}px`}
            cy={`${cent * 16}px`}
            fill="transparent"
            stroke="currentColor"
            strokeWidth={`${stroke * 16}px`}
            strokeLinecap="round"
            opacity=".25"
          />
          <circle
            r={`${rad * 16}px`}
            cx={`${cent * 16}px`}
            cy={`${cent * 16}px`}
            fill="transparent"
            stroke="currentColor"
            strokeWidth={`${stroke * 16}px`}
            strokeLinecap="round"
            strokeDasharray={`${cir * 16}px ${cir * 16}px`}
            strokeDashoffset={`${(cir - (percent / 100) * cir) * 16}px`}
          />
        </svg>
        <h1 className="stat-percentage">{percent}</h1>
      </div>
      <p className="stat-description">{desc}</p>
    </div>
  )
}

Stat.propTypes = {
  size: PropTypes.number.isRequired,
  rad: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  desc: PropTypes.string,
}

Stat.defaultProps = {
  size: 6,
  rad: 2.75,
  stroke: 0.5,
}

export default Stat
