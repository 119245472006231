import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Step = ({ link, title, icon }) => {
  return (
    <div className="step-item">
      <Link to={`/resource/${link}`} aria-label={title} className="step-icon">
        {icon}
      </Link>
      <h3 className="step-title">{title}</h3>
    </div>
  )
}

Step.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}

export default Step
