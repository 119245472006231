import React from "react"
import {
  FaBookReader,
  FaBrain,
  FaClipboardList,
  FaSeedling,
  FaTachometerAlt,
} from "react-icons/fa"
import "./index.sass"
import Step from "./Step"

const FiveSteps = () => (
  <div className="step-wrapper">
    <Step link="learn" title="Learn" icon={<FaBookReader />} />
    <Step link="mindset" title="Mindset" icon={<FaBrain />} />
    <Step link="grow" title="Grow" icon={<FaSeedling />} />
    <Step link="plan" title="Plan" icon={<FaClipboardList />} />
    <Step link="optimize" title="Optimize" icon={<FaTachometerAlt />} />
  </div>
)

export default FiveSteps
