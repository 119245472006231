import React from "react"
import "./index.sass"
import Stat from "./Stat"

const FiveStats = () => (
  <>
    <p>
      According to an Educational Advisory Board report, out of a class of 100:
    </p>

    <div className="stat-wrapper">
      <Stat
        percent={28}
        desc="Students will graduate and work in a job that does not require a college degree"
      />

      <Stat percent={22} desc="Students will drop out of college" />

      <Stat
        percent={12}
        desc="Students will still be taking college classes after 6 years"
      />

      <Stat percent={3} desc="Students earned an Associate’s degree" />

      <Stat
        percent={35}
        desc="Students will graduate and will work in a career that requires a college degree"
      />
    </div>
  </>
)

export default FiveStats
